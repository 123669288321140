import { Box, Typography } from "@mui/material";

const coursesData = [
  {
    heading: "Data Structures",
    desc: "Enhance your coding skills through personalized one-on-one mentoring sessions to prepare yourself for interviews.",
    timeFrame: "<2 Months",
    points: [
      "Specialized Educational Sessions",
      "Fundamental Concepts and Techniques",
      "Lectures, discussion and practical exercises",
      "Structured curriculum",
      "Coveres basic and advanced data structures",
      "Customized based on target organization",
    ],
  },
  {
    heading: "System Design",
    desc: "Unlock your potential as a masterful architect of scalable and efficient software systems with our comprehensive System Design course",
    timeFrame: "<2 Months",
    points: [
      "Techniques for designing complex and scalable systems",
      "Design Methodologies",
      "System Architecture",
      "Requirement Analysis",
      "Best practices for efficient interview",
      "Customizable",
    ],
  },
  {
    heading: "Behavioral",
    desc: "Supercharge your tech career with our Behavioral Course: Master the art of effective communication and leadership",
    timeFrame: "<2 Months",
    points: [
      "One-on-One focused Tutoring sessions",
      "Personalized coaching and guidance",
      "Assess your current level of proficiency",
      "Collaborative refinement for areas that need fine-tuning",
      "Dive into practice questions together",
      "Placement assistance",
    ],
  },
  {
    heading: "Mock Interview",
    desc: "Level up your interviewing skills and land your dream tech job with our Mock interview sessions",
    timeFrame: "<2 Months",
    points: [
      "One-on-One simulated job interviews",
      "Customized for specific software engineering role",
      "Covers variety of practice questions",
      "Specific feedback after each answer",
      "Tailored guidance and feedback",
      "Sessions on Coding, System Design or Behavioral rounds",
    ],
  },
];

export const FourthSection = () => {
  return (
    <Box sx={{ backgroundColor: "#f6fbff", py: "60px", textAlign: "center" }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1140px",
          marginLeft: "auto",
          marginRight: "auto",
          "@media screen and (max-width: 991px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#262641",
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: 700,
            "@media screen and (max-width: 767px)": {
              fontSize: "24px",
            },
            fontSize: "30px",
            lineHeight: "35px",
          }}
        >
          Select one or a combination of courses below to achieve your goal
        </Typography>
        <Box
          sx={{
            "@media screen and (max-width: 991px)": {
              gridRowGap: "40px",
              gridTemplateColumns: "1fr",
            },
            gridColumnGap: "26px",
            gridRowGap: "16px",
            gridTemplateRows: "auto",
            gridTemplateColumns: "1fr 1fr",
            gridAutoColumns: "1fr",
            display: "grid",
          }}
        >
          {coursesData.map((course) => {
            return (
              <Box
                sx={{
                  "@media screen and (max-width: 767px)": {
                    marginTop: 0,
                  },
                  textAlign: "left",
                  backgroundColor: "#fff",
                  border: "1px solid #e3e7ec",
                  borderRadius: "10px",
                  flexDirection: "column",
                  justifyContent: "space-betwen",
                  marginTop: "20px",
                  transition: "all .4s",
                  display: "flex",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      "@media screen and (max-width: 767px)": {
                        padding: "20px",
                      },
                      borderBottom: "1px solid #e3e7ec",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "24px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#262641",
                        textTransform: "uppercase",
                        marginTop: 0,
                        marginBottom: "10px",
                        lineHeight: "120%",
                      }}
                      variant="h5"
                    >
                      {course.heading}
                    </Typography>
                    <Typography sx={{ marginBottom: "10px", color: "#444952", fontSize: "15px" }}>
                      {course.desc}
                    </Typography>
                  </Box>
                  {course.points.map((point) => {
                    return (
                      <Box
                        sx={{
                          "@media screen and (max-width: 767px)": {
                            paddingLeft: "15px",
                          },
                          gridColumnGap: "16px",
                          borderBottom: "1px solid #e3e7ec",
                          alignItems: "flex-start",
                          padding: "12px 12px 12px 24px",
                          display: "flex",
                        }}
                      >
                        <img
                          src="/static/images/new-landing-page/twelveth-section/tick.svg"
                          style={{ width: "18px", marginTop: "3px" }}
                        />
                        <Typography
                          sx={{
                            color: "#000",
                            alignItems: "center",
                            fontSize: "15px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                        >
                          {point}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
