import axios from "axios";
import {
  GoogleLoginRequest,
  LoginRequestPayload,
  RegisterRequestPayload,
  createAppointmentRequest,
} from "./model";

const CLIENT_SERVICE_URL = "http://localhost:4001";

const login = async (payload: LoginRequestPayload) =>
  axios.post(`${CLIENT_SERVICE_URL}/api/v1/auth/login`, payload);

const googleLogin = async (payload: GoogleLoginRequest) =>
  axios.post(`${CLIENT_SERVICE_URL}/api/v1/auth/login-google`, payload);

const register = async (payload: RegisterRequestPayload) =>
  axios.post(`${CLIENT_SERVICE_URL}/api/v1/auth/sign-up`, payload);

const createAppointment = async (payload: createAppointmentRequest) =>
  axios.post(`${CLIENT_SERVICE_URL}/api/v1/appointment`, payload);

export { login, googleLogin, register, createAppointment };
