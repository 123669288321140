import { FC, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { UserCircle as UserCircleIcon } from "../../../icons/user-circle";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { getUser } from "../../auth/authSlice";
import { TimeZone } from "../../../utils/time-zone";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";

const useStyles = makeStyles(() => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const AccountGeneralSettings: FC = (props) => {
  const classes = useStyles();
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = useSelector(getUser);
  console.log(user);

  const [isEmailEditDisabled, setIsEmailEditDisabled] = useState<boolean>(true);

  const nameChangeFormik = useFormik({
    initialValues: {
      fullName: `${user.givenName} ${user.familyName}`,
      submit: null,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().max(255).required("Name is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {},
  });

  const emailChangeFormik = useFormik({
    initialValues: {
      email: user.email,
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {},
  });

  const timeZoneFormik = useFormik({
    initialValues: {
      timeZone: "Pacific Standard Time",
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Time zone is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {},
  });

  const passwordChangeFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      submit: null,
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().min(7).max(255).required("Current password is required"),
      newPassword: Yup.string().min(7).max(255).required("New password is required"),
      confirmNewPassword: Yup.string()
        .min(7)
        .max(255)
        .required("Confirmation of new password is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {},
  });

  return (
    <Box sx={{ mt: 4 }} {...props}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <IconButton>
                  <Avatar
                    src="#"
                    sx={{
                      height: 90,
                      mr: 2,
                      width: 90,
                    }}
                  >
                    <UserCircleIcon fontSize="medium" />
                  </Avatar>
                </IconButton>
                <Button>Change</Button>
              </Box>
              <form noValidate onSubmit={nameChangeFormik.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    mt: 3,
                    alignItems: "center",
                  }}
                >
                  <TextField
                    error={Boolean(
                      nameChangeFormik.touched.fullName && nameChangeFormik.errors.fullName
                    )}
                    helperText={
                      nameChangeFormik.touched.fullName && nameChangeFormik.errors.fullName
                    }
                    defaultValue={nameChangeFormik.values.fullName}
                    label="Full Name"
                    size="medium"
                    name="fullName"
                    sx={{
                      flexGrow: 1,
                      mr: 3,
                    }}
                    onBlur={nameChangeFormik.handleBlur}
                    onChange={nameChangeFormik.handleChange}
                    value={nameChangeFormik.values.fullName}
                  />
                  <Button type="submit">Save</Button>
                </Box>
              </form>

              <form noValidate onSubmit={emailChangeFormik.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    mt: 3,
                    alignItems: "center",
                  }}
                >
                  <TextField
                    error={Boolean(
                      emailChangeFormik.touched.email && emailChangeFormik.errors.email
                    )}
                    helperText={emailChangeFormik.touched.email && emailChangeFormik.errors.email}
                    defaultValue={emailChangeFormik.values.email}
                    disabled={isEmailEditDisabled}
                    label="Email Address"
                    name="email"
                    required
                    size="medium"
                    sx={{
                      flexGrow: 1,
                      mr: 3,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderStyle: "dashed",
                      },
                    }}
                    onBlur={emailChangeFormik.handleBlur}
                    onChange={emailChangeFormik.handleChange}
                    value={emailChangeFormik.values.email}
                  />
                  <Button
                    type="submit"
                    onClick={() => setIsEmailEditDisabled(!isEmailEditDisabled)}
                  >
                    Edit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Preferences</Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Typography sx={{ mb: 1 }} variant="subtitle1">
                Choose your time zone.
              </Typography>
              <form noValidate onSubmit={timeZoneFormik.handleSubmit}>
                <FormControl
                  margin="normal"
                  error={Boolean(timeZoneFormik.touched.timeZone && timeZoneFormik.errors.timeZone)}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Time zone</InputLabel>
                  <Select
                    value={timeZoneFormik.values.timeZone}
                    label="Time zone"
                    name="timeZone"
                    onBlur={timeZoneFormik.handleBlur}
                    onChange={timeZoneFormik.handleChange}
                    MenuProps={{
                      style: {
                        maxHeight: "300px",
                        minWidth: "150px",
                      },
                      PaperProps: {
                        className: classes.scrollbar,
                        style: {
                          width: "1px",
                        },
                      },
                    }}
                    inputProps={{
                      autoComplete: "on",
                    }}
                    defaultValue="Pacific Standard Time"
                  >
                    {TimeZone.map((zone) => {
                      return <MenuItem value={zone.value}>{zone.text}</MenuItem>;
                    })}
                  </Select>
                  {timeZoneFormik.touched.timeZone && (
                    <FormHelperText>{timeZoneFormik.errors.timeZone}</FormHelperText>
                  )}
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Change your password</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <form noValidate onSubmit={passwordChangeFormik.handleSubmit}>
                <Box mb={[3]}>
                  <TextField
                    error={Boolean(
                      passwordChangeFormik.touched.currentPassword &&
                        passwordChangeFormik.errors.currentPassword
                    )}
                    helperText={
                      passwordChangeFormik.touched.currentPassword &&
                      passwordChangeFormik.errors.currentPassword
                    }
                    fullWidth
                    label="Current Password"
                    required
                    type="password"
                    size="medium"
                    name="currentPassword"
                    onBlur={passwordChangeFormik.handleBlur}
                    onChange={passwordChangeFormik.handleChange}
                    value={passwordChangeFormik.values.currentPassword}
                  />
                </Box>
                <Box mb={[3]}>
                  <TextField
                    error={Boolean(
                      passwordChangeFormik.touched.newPassword &&
                        passwordChangeFormik.errors.newPassword
                    )}
                    helperText={
                      passwordChangeFormik.touched.newPassword &&
                      passwordChangeFormik.errors.newPassword
                    }
                    label="New Password"
                    fullWidth
                    required
                    type="password"
                    size="medium"
                    name="newPassword"
                    onBlur={passwordChangeFormik.handleBlur}
                    onChange={passwordChangeFormik.handleChange}
                    value={passwordChangeFormik.values.newPassword}
                  />
                </Box>

                <Box mb={[3]}>
                  <TextField
                    error={Boolean(
                      passwordChangeFormik.touched.confirmNewPassword &&
                        passwordChangeFormik.errors.confirmNewPassword
                    )}
                    helperText={
                      passwordChangeFormik.touched.confirmNewPassword &&
                      passwordChangeFormik.errors.confirmNewPassword
                    }
                    label="Confirm New Password"
                    fullWidth
                    required
                    type="password"
                    size="medium"
                    name="confirmNewPassword"
                    onBlur={passwordChangeFormik.handleBlur}
                    onChange={passwordChangeFormik.handleChange}
                    value={passwordChangeFormik.values.confirmNewPassword}
                  />
                </Box>

                <Button type="submit" color="primary" variant="contained">
                  Change password
                </Button>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
