/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { AppBarProps, Button } from "@mui/material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu as MenuIcon } from "../../icons/menu";
import { Bell as BellIcon } from "../../icons/bell";

import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import { AccountPopover } from "./account-popover";
import { NotificationsPopover } from "./notifications-popover";
import { useSelector } from "react-redux";
import { isUserAuthenticated, getUser } from "../auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import { AppRoutes } from "../../routing/routes";
import { push } from "connected-react-router";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ChosenColorCode } from "../new-landing-page/standard-color";

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}

type Language = "en" | "de" | "es";

const languages: Record<Language, string> = {
  en: "/static/icons/uk_flag.svg",
  de: "/static/icons/de_flag.svg",
  es: "/static/icons/es_flag.svg",
};

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const NotificationsButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [unread, setUnread] = useState<number>(0);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  const handleUpdateUnread = (value: number): void => {
    setUnread(value);
  };

  return (
    <>
      {/* <Tooltip title="Notifications">
        <IconButton ref={anchorRef} sx={{ ml: 1 }} onClick={handleOpenPopover}>
          <Badge color="error" badgeContent={unread}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        onUpdateUnread={handleUpdateUnread}
        open={openPopover}
      /> */}
    </>
  );
};

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  // To get the user from the authContext, you can use
  // const { user } = useAuth();

  const user = useSelector(getUser);

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          ml: 2,
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          src="#"
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onOpenSidebar, ...other } = props;
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useAppDispatch();

  const handleLogoClick = () => {
    dispatch(push(AppRoutes.DEFAULT));
  };

  const handleAboutClick = () => {
    dispatch(push(AppRoutes.ABOUT));
  };

  // const handleTestimonialsClick = () => {
  //   dispatch(push(AppRoutes.TESTIMONIALS));
  // };

  const handleInterviewSessionsClick = () => {
    dispatch(push(AppRoutes.INTERVIEW_SESSIONS));
  };

  const handleTutoringSessionsClick = () => {
    dispatch(push(AppRoutes.TUTORING_SESSIONS));
  };

  const handleGetStartedClick = () => {
    // @ts-ignore
    window?.Calendly.initPopupWidget({ url: "https://calendly.com/prakhar2082/15min" });
  };

  const handleContactUsClick = () => {
    dispatch(push(AppRoutes.CONTACT_US));
  };

  const style = isAuthenticated
    ? {
        left: {
          lg: 280,
        },
        width: {
          lg: "calc(100% - 280px)",
        },
      }
    : {};

  return (
    <>
      <DashboardNavbarRoot sx={style} {...other}>
        {/* join next webinar */}
        {/* <Box
          display="flex"
          sx={{ backgroundColor: "#daf0ff", py: [1, 2] }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: ["12px", "14px"],
              color: "#201845",
              lineHeight: "24px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            Get complete details of the course and our training methodology
            <a
              style={{
                color: "#3996d2",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: 600,
                textAlign: "center",
                marginLeft: "5px",
                cursor: "pointer",
              }}
            >
              Join our next webinar
            </a>
            <ArrowRightAltIcon sx={{ color: "#3996d2", verticalAlign: "middle" }} />
          </Typography>
        </Box> */}
        {/* navbar */}
        <Box>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "transparent",
              px: [2, 5, 9],
              py: [1],
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ height: "50px", width: "150px" }}>
                <a href="">
                  <img src="/static/images/logo.png" alt="Logo" />
                </a>
              </Box>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: `${ChosenColorCode.CRACKTECH_CHOICE}`,
                  py: "11px",
                  px: "24px",
                  borderRadius: "8px",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: `${ChosenColorCode.CRACKTECH_CHOICE}`,
                  },
                  minWidth: "201px",
                }}
                onClick={handleGetStartedClick}
              >
                Sign Up for Free Introduction
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
