import { Box } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { FirstSection } from "./first-section";
import { Footer } from "./footer";
import { ThirdSection } from "./third-section";
import { SecondSection } from "./second-section";
import { FourthSection } from "./fourth-section";
import { FifthSection } from "./fifth-section";

export const NewLandingPage = () => {
  return (
    <Box mt={["8.5rem"]}>
      {/* first section */}
      <FirstSection />

      {/* second section */}
      <SecondSection />

      {/* fourth section */}
      <ThirdSection />

      {/* sixth section */}
      <FourthSection />

      {/* tenth section  */}
      <FifthSection />

      {/* footer  */}
      <Footer />
    </Box>
  );
};
