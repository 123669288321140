export interface LoginRequestPayload {
  email: string;
  password: string;
}

export enum UserType {
  ADMIN = "admin",
  USER = "User",
}

export interface LoginResponsePayload {
  token: string;
  user: {
    givenName: string;
    familyName: string;
    email: string;
    country: string;
    phone: string;
    role: string;
    id: string;
  };
}

export interface GoogleLoginRequest {
  credentials: string;
}

export interface RegisterRequestPayload {
  givenName: string;
  familyName: string;
  email: string;
  country: string;
  phone: string;
  password: string;
  role: string;
}

export interface createAppointmentRequest {
  userID: string;
  appointment: {
    startTime: Date;
    endTime: Date;
    expertID: string;
  };
}
