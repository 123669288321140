import type { ReactNode } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import { DashboardSidebar } from "./dashboard-sidebar";
import { Box } from "@mui/material";
import { isUserAuthenticated } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  sidebarPaddingAuthenticated: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 280,
    },
  },
}));

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
}));

export const DashboardLayout = (props: DashboardLayoutProps) => {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const classes = useStyles();
  console.log(isAuthenticated);
  return (
    <>
      <DashboardLayoutRoot className={isAuthenticated ? classes.sidebarPaddingAuthenticated : ""}>
        <Box sx={{ display: "flex", flex: "1 1 auto", flexDirection: "column", width: "100%" }}>
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
      {isAuthenticated && (
        <DashboardSidebar onClose={(): void => setIsSidebarOpen(false)} open={isSidebarOpen} />
      )}
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
