import { makeStyles } from "@material-ui/styles";
import { Typography, Box, Grid, Card, CardMedia, CardContent } from "@mui/material";

const cardContents = [
  {
    imageURL: "/static/images/interview-session/card-image-1.png",
    title: "Interveiw Structure",
    desc: "By focusing on a few foundational items, specifically, the right structure touse, how to organize the information, and the right amount of detail to provide,we can quickly improve your interview skills.",
  },
  {
    imageURL: "/static/images/interview-session/card-image-2.png",
    title: "Secret Unlock",
    desc: "By practicing, we will likely uncover five to ten areas that have likely been holding you back from getting the job. We will cater the consulting to the specific position/company, and remove any information that is getting the way of your success.",
  },
  {
    imageURL: "/static/images/interview-session/card-image-3.png",
    title: "Custom Plan",
    desc: "At the end of our session, we will discuss a specific plan for how to improve and how to spend your preparation and practice time. By addressing these concerns, we will increase the odds of you landing the job!",
  },
];

export const InterviewSessions = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        width="100%"
        height="100vh"
        sx={{ display: "flex", flexDirection: { xs: "column-reverse", sm: "row" } }}
      >
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            order: { xs: 2, sm: 1 },
            height: { sm: "100%" },
          }}
          ml={[1, 10]}
          mt={[2, 5]}
        >
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography variant="h2">1:1</Typography>
            </Box>
            <Box>
              <Typography variant="h2">Interview Consulting</Typography>
            </Box>
            <Box>
              <Typography variant="h2">with Hello</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mt={[2, 5]}>
            <Box>
              <Typography sx={{ color: "#549d78", fontSize: "24px", fontWeight: 700 }}>
                Learn how to implement five to
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#549d78", fontWeight: 700, fontSize: "24px" }}>
                ten major changes in one hour
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            height: { sm: "100%" },
            width: "100%",
            order: { xs: 1, sm: 2 },
          }}
        >
          <img
            src="/static/images/interview-session/interview-session-background.jpg"
            alt="interview-session-background"
            style={{
              width: "100%",
              height: "100%",
              filter: "none",
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection={["column", "row"]}>
        <Box
          sx={{
            backgroundColor: "#538373",
          }}
          py={[2, 5, 12]}
          px={[2, 5, 12]}
        >
          <Typography variant="caption" sx={{ fontSize: "17px", color: "white" }}>
            I will conduct one-on-one simulated job interviews that will be designed to help you
            practice and improve your interview skills. It will be customized to align with the
            specific software engineering roles that you are targeting. We will cover a variety of
            practice questions that are relevant to the specific software engineering role you are
            targeting. After each answer, I will provide you with specific feedback on your
            performance, highlighting your strengths and areas for improvement.
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "white" }}>
            My goal is to provide you with tailored guidance and feedback to help you enhance your
            interviewing skills and boost your confidence for actual software engineering job
            interviews.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        px={[2, 5, 12]}
        py={[3, 5, 12]}
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#ecf0f1" }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h2"
            sx={{ fontSize: ["24px", "48px"], fontWeight: 700, textAlign: "center" }}
          >
            Practice makes perfect
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: "18px", fontWeight: 400, color: "#000000", textAlign: "center" }}
          >
            The key to mastering your interview skills and feeling confident will only come from
            practice.
          </Typography>
        </Box>
        <Box py={[2, 5, 10]} px={[2, 5, 12]}>
          <Grid container spacing={3} alignItems="stretch">
            {cardContents.map((card) => {
              return (
                <Grid item xs={12} sm={12} md={4} sx={{ display: "flex" }}>
                  <Card
                    sx={{
                      backgroundColor: "#ecf0f1",
                      boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.075)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: ["center", "flex-start"],
                    }}
                  >
                    <CardMedia
                      image={card.imageURL}
                      title={card.title}
                      sx={{
                        height: "200px",
                        width: "200px",
                        objectFit: "cover",
                        textAlign: ["center", "left"],
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px !important",
                          pb: [1],
                          textAlign: ["center", "left"],
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: 1.6,
                          color: "#595959",
                          textAlign: ["center", "left"],
                        }}
                      >
                        {card.desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
