import { Box, Typography } from "@mui/material";
import { useState } from "react";

interface studentTestimonials {
  imageURL: string;
  name: string;
  message: string;
  companyPlaced: string;
  designation: string;
}

const studentsData: studentTestimonials[] = [
  {
    imageURL: "/static/images/new-landing-page/tenth-section/testimonials-data/Meta-Logo.png",
    name: "W.",
    message:
      "Prakhar is one of the best interviewing coach I have met, and has definitely upped my game. I haven’tinterviewed for 8 years and got rusty. Prakhar’s structured approach starting from the fundamentals,combined with practices and simulations ultimately enabled me to get the offers I wanted. He is notcheap, but it was definitely one of the best investments I have made.",
    companyPlaced: "/static/images/new-landing-page/tenth-section/testimonials-data/Meta-Logo.png",
    designation: "Engineering Director",
  },
  {
    imageURL: "/static/images/new-landing-page/tenth-section/testimonials-data/Salesforce-logo.jpg",
    name: "Shimpa",
    message:
      "Prakhar is a great mentor to have. For someone like me who wanted to make a transition to FAANG, his mentorship helped me succeed and I had got offers from Amazon, Walmart Labs and Salesforce, where I am working currently. He is really dedicated and passionate about teaching and works really hard at your areas of improvement. I would strongly recommend him !!",
    companyPlaced:
      "/static/images/new-landing-page/tenth-section/testimonials-data/Salesforce-logo.jpg",
    designation: "Lead, Software Engineering (LMTS)",
  },
  {
    imageURL: "/static/images/new-landing-page/tenth-section/testimonials-data/Bloomberg-logо.jpg",
    name: "Suyi Liu",
    message:
      "I really enjoyed the sessions with Prakhar. He is super knowledgeable, organized and I have 0 rejections from any interview after training with Prakhar and got multiple offers.",
    companyPlaced:
      "/static/images/new-landing-page/tenth-section/testimonials-data/Bloomberg-logо.jpg",
    designation: "Senior Software Engineer",
  },
  {
    imageURL: "/static/images/new-landing-page/tenth-section/testimonials-data/Amazon_logo.png",
    name: "Rongrong Miao",
    message:
      "Prakhar knows system design inside and out. He is the only person who can make the concepts easy to understand. If you have an interview deadline and don't want to go through system design books, go with Prakhar!",
    companyPlaced:
      "/static/images/new-landing-page/tenth-section/testimonials-data/Amazon_logo.png",
    designation: "Software Engineer",
  },
];

export const FifthSection = () => {
  const [cards, setCards] = useState<studentTestimonials[]>(studentsData);

  const handleMoveRight = () => {
    // Logic to move the cards to the left
    const firstCard = cards.shift() as studentTestimonials;
    setCards([...cards, firstCard]);
  };

  const handleMoveLeft = () => {
    // Logic to move the cards to the right
    const lastCard = cards.pop() as studentTestimonials;
    setCards([lastCard, ...cards]);
  };

  return (
    <Box sx={{ paddingTop: "20px", paddingBottom: 0, position: "relative", textAlign: "center" }}>
      <Box
        sx={{
          "@media screen and (max-width: 991px)": {
            paddingTop: "20px",
            px: "20px",
          },
          width: "100%",
          paddingTop: "45px",
          position: "relative",
          maxWidth: "1140px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              "@media screen and (max-width: 767px)": {
                fontSize: "28px",
              },
              color: "#201845",
              marginBottom: 0,
              fontSize: "34px",
              fontWeight: 700,
              lineHeight: "130%",
            }}
          >
            Our student success stories
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              "@media screen and (max-width: 767px)": {
                width: "95%",
                marginLeft: "auto",
                marginRight: "auto",
              },
              zIndex: 0,
              alignItems: "center",
              marginTop: "80px",
              paddingBottom: "40px",
              display: "flex",
              position: "relative",
            }}
          >
            <a
              onClick={handleMoveRight}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "rgba(57,150,210,.1)",
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "auto",
                marginBottom: "auto",
                display: "flex",
                position: "absolute",
                top: "0%",
                bottom: "0%",
                left: "auto",
                right: " -5%",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: "10px",
                }}
                src="static\images\new-landing-page\tenth-section\right-forward.svg"
                alt="left-forward-button"
              />
            </a>
            <a
              onClick={handleMoveLeft}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "rgba(57,150,210,.1)",
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "auto",
                marginBottom: "auto",
                display: "flex",
                position: "absolute",
                top: "0%",
                bottom: "0%",
                left: "-5%",
                right: "auto",
                opacity: ".5",
                transform: "rotate(180deg)",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: "10px",
                }}
                src="static\images\new-landing-page\tenth-section\left-forward.svg"
                alt="left-forward-button"
              />
            </a>
            <Box
              sx={{
                width: "95%",
                marginLeft: "auto",
                marginRight: "auto",
                overflow: "hidden",
                touchAction: "pan-y",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  display: "flex",
                  transitionProperty: "transform",
                  transitionTimingFunction:
                    "var(--swiper-wrapper-transition-timing-function,initial)",
                  boxSizing: "content-box",
                  transform: "translate3d(0px,0,0)",
                }}
              >
                {cards.map((student) => {
                  return (
                    <Box
                      sx={{
                        width: "345px",
                        marginRight: "24px",
                        flexShrink: 0,
                        height: "100%",
                        position: "relative",
                        transitionProperty: "transform",
                        display: "block",
                        transform: "translateZ(0)",
                        backfaceVisibility: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          height: "450px",
                          color: "#142631",
                          textAlign: "left",
                          backgroundColor: "#fff",
                          border: "1px solid #def2ff",
                          borderRadius: "16px",
                          flexDirection: "column",
                          flex: 1,
                          justifyContent: "space-between",
                          padding: "40px",
                          fontSize: "18px",
                          lineHeight: "130%",
                          display: "flex",
                          boxShadow: "1px 1px 3px 3px rgba(218,240,255,.4)",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              flexDirection: "column",
                              display: "flex",
                            }}
                          >
                            <Box sx={{ textAlign: "left" }}>
                              <Box
                                sx={{
                                  color: "#625d76",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  display: "flex",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#201845",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    marginRight: "10px",
                                    fontWeight: 600,
                                    lineHeight: "130%",
                                    fontSize: "18px",
                                  }}
                                >
                                  {student.name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  lineHeight: "130%",
                                  fontSize: "14px",
                                  color: "#142631",
                                  marginRight: "8px",
                                }}
                              >
                                Curr Org :
                              </Typography>
                              <img
                                src={student.imageURL}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  objectFit: "contain",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center center" }}>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                lineHeight: "130%",
                                fontSize: "14px",
                                color: "#142631",
                              }}
                            >
                              Designation:
                            </Typography>
                            <Typography
                              sx={{
                                color: "#201845",
                                marginLeft: "10px",
                                marginTop: 0,
                                marginBottom: "10px",
                                marginRight: 0,
                                fontWeight: 600,
                                lineHeight: "130%",
                                fontSize: "16px",
                              }}
                            >
                              {student.designation}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                color: "#142631",
                                textAlign: "left",
                                fontSize: "18px",
                                lineHeight: "130%",
                                fontWeight: 400,
                              }}
                            >
                              {student.message}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
