import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { startAppListening } from "../../app/listenerMiddleware";
import type { RootState } from "../../app/type";
import { login, googleLogin, register } from "../../boundaries/ad-lnx-backend/auth/api";
import {
  GoogleLoginRequest,
  LoginRequestPayload,
  LoginResponsePayload,
  RegisterRequestPayload,
  UserType,
} from "../../boundaries/ad-lnx-backend/auth/model";
import { AppRoutes } from "../../routing/routes";

interface user {
  familyName: string;
  givenName: string;
  email: string;
  country: string;
  phone: string;
  role: string;
  id: string;
}

export interface AuthState {
  isLoggedIn: boolean;
  token: string;
  role: string;
  user: user;
}

const initialState: AuthState = {
  isLoggedIn: false,
  role: "",
  token: "",
  user: {
    familyName: "",
    givenName: "",
    email: "",
    country: "",
    phone: "",
    role: "",
    id: "",
  },
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  "auth/loginThunk",
  async (payload: LoginRequestPayload, thunkApi) => {
    const response = await login(payload);
    const data = response.data as LoginResponsePayload;
    return data;
  }
);

export const googleLoginAsync = createAsyncThunk(
  "auth/googleLoginThunk",
  async (payload: GoogleLoginRequest, thunkApi) => {
    const response = await googleLogin(payload);
    const data = response.data as LoginResponsePayload;
    return data;
  }
);

// Define the async thunk for registration
export const registerAsync = createAsyncThunk(
  "auth/registerThunk",
  async (payload: RegisterRequestPayload, thunkApi) => {
    const response = await register(payload);
    const data = response.data as LoginResponsePayload;
    return data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.role = action.payload.user.role;
      state.user = action.payload.user;
    });
    builder.addCase(googleLoginAsync.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.role = action.payload.user.role;
    });
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      // this is optional if you are returning token after registration
      state.token = action.payload.token;
      state.role = action.payload.user.role;
    });
  },
});

startAppListening({
  actionCreator: loginAsync.fulfilled,
  effect: (action, listenerApi) => {
    if (listenerApi.getState().auth.role === UserType.ADMIN) {
      listenerApi.dispatch(push(AppRoutes.DASHBOARD));
    } else if (listenerApi.getState().auth.role === UserType.USER) {
      listenerApi.dispatch(push(AppRoutes.DASHBOARD));
    }
  },
});

startAppListening({
  actionCreator: googleLoginAsync.fulfilled,
  effect: (action, listenerApi) => {
    if (listenerApi.getState().auth.isLoggedIn === true) {
      listenerApi.dispatch(push("/user/view"));
    }
  },
});

startAppListening({
  actionCreator: registerAsync.fulfilled,
  effect: (action, listenerApi) => {
    if (listenerApi.getState().auth.isLoggedIn === true) {
      listenerApi.dispatch(push(AppRoutes.DASHBOARD));
    }
  },
});

export const { reset } = authSlice.actions;

export const isUserAuthenticated = (state: RootState) => state.auth.isLoggedIn;

export const getToken = (state: RootState) => state.auth.token;

export const getRole = (state: RootState) => state.auth.role;

export const getUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
