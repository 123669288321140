import { Box, Typography, Button } from "@mui/material";

const gridBoxes = [
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-1.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-2.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-3.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-4.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-5.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
  {
    imageURL: "/static/images/new-landing-page/grid-box-image-6.svg",
    heading: "Designed by Tier-1 instructors",
    desc: "Get trained and mentored by tech leads, hiring managers, and recruiters from Tier-1 tech companies",
  },
];

export const SecondSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f6fbff",
        textAlign: "left",
        paddingTop: "30px",
        paddingBottom: "80px",
      }}
    >
      <Box
        sx={{
          mx: "auto",
          maxWidth: "1140px",
          pt: ["20px", "45px"],
          "@media screen and (max-width:991px)": {
            padding: "20px",
          },
        }}
      >
        <Typography
          sx={{ color: "#201845", fontSize: "34px", fontWeight: 700, lineHeight: "130%" }}
        >
          About Me
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: "40px",
            flexDirection: "row",
          }}
        >
          <Box>
            <div
              style={{
                marginBottom: "40px",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "150%",
                color: "#44555f",
                fontFamily: "Inter",
              }}
            >
              I am <strong>Prakhar</strong> and I have been working as a Senior Software Engineer in
              <strong> Google</strong> since 2018. With unwavering determination and persistent
              effort, I delved into the world of programming, forging my path towards a fulfilling
              career in software engineering. <Typography>&nbsp;</Typography>During my college
              years, I embarked on a journey to master <strong>data structures</strong>,{" "}
              <strong>algorithms</strong>, and <strong>system design interviews</strong>, seeking a
              reliable approach to tackle and conquer the challenging problems. However, what I
              encountered was a market flooded with low-quality information products that simply
              quoted college textbooks and freely available online resources. Determined to excel in
              these interviews, I set out to develop my own methodology that would enable me to
              consistently ace these challenges. <Typography>&nbsp;</Typography>As of 2023, I have
              conducted <strong>more than 1,000 </strong>one-on-one interview and coaching sessions.
              Through my meticulously crafted methodology, I have witnessed a consistent
              transformation of individuals into top-tier candidates and high-performing engineers,
              who achieve comparable results and secure lucrative offers.{" "}
              <Typography>&nbsp;</Typography>Some companies that my clients have received offers
              from are- <strong>Google, Meta, Microsoft, Amazon, Salesforce</strong> and many other
              top Tech companies.
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
