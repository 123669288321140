import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import history from "./app/history";
import store from "./app/state";

const initialOptions = {
  "client-id": "ATgw0-hcTgMslZoPj5fWpYd3tSSykDuh4lfJyhv1uGe9pLYVrbPR2VsCJ7lpSFtdUdTz8XWtsYqRpA0v",
};

const element = document.getElementById("root");
ReactDOM.render(
  <GoogleOAuthProvider clientId="477624586125-v35fr087cpqhij3upbqsm4r67ngdq515.apps.googleusercontent.com">
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </GoogleOAuthProvider>,

  element
);
