import { useEffect } from "react";
import { Box, Card, Container, Divider, Link, Typography } from "@mui/material";
import { JWTRegister } from "./jwt-register";
import { useAppDispatch } from "../../../app/hooks";
import { push } from "connected-react-router";
import { AppRoutes } from "../../../routing/routes";

export const Register = () => {
  const dispatch = useAppDispatch();

  const handleLoginOnClick = () => {
    dispatch(push(AppRoutes.LOGIN));
  };

  return (
    <>
      <title>Register</title>
      <Box
        component="main"
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Card elevation={16} sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">Register</Typography>
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
                Register on CrackTech.io
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <JWTRegister />
            </Box>
            <Divider sx={{ my: 3 }} />
            <div>
              <Link onClick={handleLoginOnClick} color="textSecondary" variant="body2">
                Already have an account?
              </Link>
            </div>
          </Card>
        </Container>
      </Box>
    </>
  );
};
