import { Box, Card, CardContent, CardHeader, Divider, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

const upcomingSessions = [
  {
    date: new Date(),
    time: "01:30-2:30",
    pricing: "100 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "50 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "80 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "80 USD",
  },
];

const completedSessions = [
  {
    date: new Date(),
    time: "10:30-2:30",
    pricing: "100 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "50 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "80 USD",
  },
  {
    date: new Date(),
    time: "02:30-3:30",
    pricing: "80 USD",
  },
];

const tabs = [
  {
    label: "Upcoming Sessions",
    value: "upcoming",
  },
  {
    label: "Completed Sessions",
    value: "completed",
  },
];
export const AllAppointments = () => {
  const [tabValue, setTabValue] = useState<string>("upcoming");

  const sessionsToRender = tabValue === "upcoming" ? upcomingSessions : completedSessions;

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box
      mt={[2, 3]}
      ml={[2, 3, 5]}
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      gap={[2, 4, 6]}
    >
      <Box width="auto">
        <Tabs
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="primary"
          value={tabValue}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      <Box display="flex" flexDirection="column">
        {sessionsToRender.map((session) => {
          return (
            <Box mb={[1, 2]}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Box display="flex" flexDirection="column" mr={[2, 3]}>
                      <Typography variant="subtitle2" fontSize={16} fontWeight={500} mb={[1]}>
                        Session Type
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2">
                        {session.date.toDateString()} • {session.time}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="textSecondary" variant="subtitle2" fontWeight="bold">
                        {session.pricing}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
