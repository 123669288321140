import { Box, Container, Divider, Typography } from "@mui/material";
import { profile } from "../../calendar/Calendar";
import { Profile } from "../../calendar/planning/schedule-details/Profile";
import { AccountGeneralSettings } from "./profile";

const profileData: profile = {
  name: "Vivek Singh",
  avatar: "",
  status: "Active",
  scheduleDate: new Date(),
  startsAt: "02:00",
  endsAt: "04:00",
  phone: "0123456789",
  email: "vivek@gmail.com",
  payments: [
    {
      date: new Date(),
      details1: "",
      details2: "",
    },
  ],
  goals: ["Interview Preparation", "Resume Building"],
  size: "1m50",
  weight: "40kg",
  age: "40years",
  desiredSportSlots: {
    startsAt: "2pm",
    endsAt: "7pm",
    days: ["Monday", "Tuesday"],
  },
  sportsExperience: "More than 6 years",
  health: "",
  extraInfo: "",
};

export const UserAccountSettings = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container>
        <Typography variant="h4">Account</Typography>
        <AccountGeneralSettings />
      </Container>
    </Box>
  );
};
