import { Box, Typography, Grid } from "@mui/material";
import { TimeCounter } from "./time-counter";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { maxHeight } from "@mui/system";
import { ChosenColorCode } from "./standard-color";

const BlogPostCardMediaWrapper = styled("div")({
  paddingTop: "calc(100% * 4 / 4)",
  position: "relative",
});

const people = [
  "/static/images/new-landing-page/people-1.png",
  "/static/images/new-landing-page/people-2.png",
  "/static/images/new-landing-page/people-3.png",
];

export const FirstSection = () => {
  return (
    <Box p={["45px", "0"]} maxWidth={["1140px"]} mx="auto">
      <Box mt={["0", "45px"]} display="flex" flexDirection={["column", "row"]} pb="40px">
        <Box
          display="flex"
          flexDirection="column"
          textAlign={["center", "left"]}
          justifyContent={["center", "flex-start"]}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                sx={{
                  marginBottom: "20px",
                  color: "#201845",
                  fontSize: ["30px", "48px"],
                  fontWeight: 700,
                  lineHeight: "120%",
                }}
                variant="h2"
              >
                Master Your Interview and Boost Your{" "}
                <span style={{ color: `${ChosenColorCode.CRACKTECH_CHOICE}` }}>Earnings</span>
              </Typography>

              <div
                style={{
                  marginBottom: "40px",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: "#44555f",
                  fontFamily: "Inter",
                }}
              >
                Personalized <strong>Data Structure</strong> and <strong>Systems Design</strong>{" "}
                course and mock interview sessions to facilitate the synergy between exceptional
                individuals and <span style={{ fontWeight: "bold" }}>FAANG+</span> organizations
              </div>

              <div
                style={{
                  marginBottom: "40px",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: "#44555f",
                  fontFamily: "Inter",
                }}
              >
                Interviewing for a new job can be nerve-wracking, but with the{" "}
                <strong>right approach</strong>, you can ace your interview and negotiate a{" "}
                <strong>higher salary</strong>.
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{ overflow: "hidden" }}>
                <img
                  src="/static/images/new-landing-page/success-4168407_1920.jpg"
                  alt="Success comes after learning, training and practice"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
