import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  Card,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { makeStyles } from "@material-ui/styles";

import type { StepIconProps } from "@mui/material";

import { JobCategoryStep } from "./job-category-step";
import { JobDetailsStep } from "./job-details-step";
import { JobDescriptionStep } from "./job-description-step";
import { Check as CheckIcon } from "../../icons/check";
import { useSelector } from "react-redux";
import { getSelectedAppointment } from "./appointmentSlice";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
    "& .MuiDayPicker-weekContainer": {
      margin: "5px 0",
    },
  },
  scrollableContainer: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const StepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;

  const highlight = active || completed;

  return (
    <Avatar
      sx={{
        height: 40,
        width: 40,
        ...(highlight && {
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
        }),
      }}
      variant="rounded"
    >
      {completed ? <CheckIcon fontSize="small" /> : icon}
    </Avatar>
  );
};

function timeFormatter(minutes: string) {
  var date = new Date(0);
  date.setSeconds(parseInt(minutes) * 60);
  return date.toISOString().substring(11, 16);
}

export const AppointmentSettings = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [complete, setComplete] = useState<boolean>(false);
  const selectedAppointment = useSelector(getSelectedAppointment);

  const defaultValue: string = "30-90";
  const startTime = timeFormatter(
    selectedAppointment?.chosenSlotAsString?.split("-")[0] ?? defaultValue
  );
  const endTime = timeFormatter(
    selectedAppointment?.chosenSlotAsString?.split("-")[1] ?? defaultValue
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setComplete(true);
  };

  const steps = [
    {
      label: "Plan",
      content: <JobCategoryStep onBack={handleBack} onNext={handleNext} />,
    },
    {
      label: "Session Detail",
      content: <JobDetailsStep onBack={handleBack} onNext={handleNext} />,
    },
    {
      label: "Confirmation",
      content: <JobDescriptionStep onBack={handleBack} onNext={handleComplete} />,
    },
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid
            item
            sm={4}
            xs={12}
            sx={{
              backgroundImage: "url(/static/mock-images/jobs/create_job_background.png)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              p: {
                xs: 4,
                sm: 6,
                md: 8,
              },
            }}
          >
            <Box maxWidth="sm">
              <Typography sx={{ mb: 3 }} variant="h4">
                Book Session
              </Typography>
              {!complete ? (
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={{
                    "& .MuiStepConnector-line": {
                      ml: 1,
                      borderLeftColor: "divider",
                      borderLeftWidth: 2,
                    },
                  }}
                >
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel StepIconComponent={StepIcon}>
                        <Typography sx={{ ml: 2 }} variant="overline">
                          {step.label}
                        </Typography>
                      </StepLabel>
                      <StepContent
                        sx={{
                          ml: "20px",
                          borderLeftColor: "divider",
                          borderLeftWidth: 2,
                          ...(activeStep === index && {
                            py: 4,
                          }),
                        }}
                      >
                        {step.content}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <div>
                  <Avatar
                    sx={{
                      backgroundColor: "success.main",
                      color: "success.contrastText",
                      height: 40,
                      width: 40,
                    }}
                  >
                    <CheckIcon />
                  </Avatar>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Almost done!
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Here’s a preview of your session
                  </Typography>
                  <Card
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      mt: 2,
                      px: 2,
                      py: 1.5,
                    }}
                    variant="outlined"
                  >
                    <div>
                      <Typography variant="subtitle1">Session Type</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {new Date(selectedAppointment.dateAsNumberInSecs! * 1000).toDateString()}{" "}
                        <Typography color="inherit" noWrap variant="caption">
                          • {`${startTime}-${endTime}`}
                        </Typography>
                      </Typography>
                    </div>
                    <div>
                      <Typography color="textSecondary" sx={{ mr: 2 }} variant="caption">
                        100 USD
                      </Typography>
                      <PayPalButtons
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: "100",
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order!.capture().then((details) => {
                            console.log({ details });
                            // const name = details.payer.name.given_name;
                            // alert(`Transaction completed by ${name}`);
                          });
                        }}
                      />
                    </div>
                  </Card>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const New = () => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  return (
    <Grid xs={12} sm={6} md={6} lg={6} item>
      <Box display="flex" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            className={classes.root}
            orientation="portrait"
            value={date}
            onChange={(newValue: any) => setDate(newValue)}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Grid>
  );
};
