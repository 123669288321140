import type { FC } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { ArrowRight as ArrowRightIcon } from "../../icons/arrow-right";
import { useSelector } from "react-redux";
import { getSelectedAppointment } from "./appointmentSlice";
import { slotBookServiceAppointmentApi } from "../../boundaries/slot-booking-backend/appointment/api";
import { getToken } from "../auth/authSlice";

interface JobDescriptionStepProps {
  onBack?: () => void;
  onNext?: () => void;
}

function timeFormatter(minutes: string) {
  var date = new Date(0);
  date.setSeconds(parseInt(minutes) * 60);
  return date.toISOString().substring(11, 16);
}

export const JobDescriptionStep: FC<JobDescriptionStepProps> = (props) => {
  const { onBack, onNext, ...other } = props;
  const selectedAppointment = useSelector(getSelectedAppointment);
  const token = useSelector(getToken);

  const defaultValue: string = "30-90";
  const startTime = timeFormatter(
    selectedAppointment?.chosenSlotAsString?.split("-")[0] ?? defaultValue
  );
  const endTime = timeFormatter(
    selectedAppointment?.chosenSlotAsString?.split("-")[1] ?? defaultValue
  );

  const handleContinueOnClick = async () => {
    const slots = selectedAppointment.chosenSlotAsString!.split("-");
    const startDate = new Date(selectedAppointment.dateAsNumberInSecs! * 1000);
    startDate.setMinutes(Number(slots[0]));

    const endDate = new Date(selectedAppointment.dateAsNumberInSecs! * 1000);
    endDate.setMinutes(Number(slots[1]));
    await slotBookServiceAppointmentApi.createAppointment({
      token: token,
      data: {
        Appointment: {
          startTime: startDate.getTime(),
          endTime: endDate.getTime(),
          expertID: "1",
          payment: 100,
        },
      },
    });

    if (onNext) onNext();
  };

  return (
    <div {...other}>
      <Typography variant="h6">Booking Appointment for</Typography>

      <Box mt={[2]} display="flex">
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" mr={[1]}>
            Date
          </Typography>
          <Typography variant="h6" mr={[1]}>
            Chosen Slot
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            ml={[1]}
            color="textSecondary"
            variant="subtitle2"
            fontSize={16}
            fontWeight="bold"
          >
            {`${new Date(selectedAppointment.dateAsNumberInSecs! * 1000).toDateString()}`}
          </Typography>
          <Typography
            ml={[1]}
            color="textSecondary"
            variant="subtitle2"
            fontSize={16}
            fontWeight="bold"
          >
            {`${startTime}-${endTime}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          endIcon={<ArrowRightIcon fontSize="small" />}
          onClick={handleContinueOnClick}
          variant="contained"
        >
          Book Session
        </Button>
        <Button onClick={onBack} sx={{ ml: 2 }}>
          Back
        </Button>
      </Box>
    </div>
  );
};

JobDescriptionStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
