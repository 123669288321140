import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  imageContainer: {
    width: "100%",
    height: 400,
    position: "relative",
    offset: "top",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  content: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    textAlign: "start",
    color: "#fff",
  },
  heading: {
    fontSize: 40,
    fontWeight: "bold",
    marginBottom: 20,
  },
  fullWidthContainer: {
    "& .MuiContainer-root": {
      maxWidth: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  return (
    <Box component="main">
      <Box className={classes.imageContainer}>
        <img
          src="/static/images/landing-page/landing-page.jpg"
          alt="Image description"
          className={classes.image}
        />
        <Box width="100%" top={["80%", "70%"]} left={["60%"]} className={classes.content}>
          <Typography variant="h6" className={classes.heading}>
            CrackTech.IO
          </Typography>
          <Button variant="contained" color="primary">
            Click me
          </Button>
        </Box>
      </Box>
      <Box my={[2, 5, 7]} mx={[2, 3]}>
        <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
          Master Your Interview and Boost Your Earnings
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
          Interviewing for a new job can be nerve-wracking, but with the right approach, you can ace
          your interview and negotiate a higher salary.
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
          With extensive experience of over 5,000+ hours of 1:1 Sessions, I will empower you to
          excel in your interviews, land your dream job, and achieve an outstanding total
          compensation package. My personalized approach ensures you receive the customized support
          and guidance you need for success. And if you prefer self-study, you can enroll in the
          comprehensive mock interview sessions.
        </Typography>
      </Box>
      {/* footer */}
      <Box sx={{ backgroundColor: "#454545" }} mt={[2, 4, 6]} px={[2, 5]}>
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          py={[2, 4, 6]}
          px={[2, 4, 6]}
          gap={[2, 3]}
        >
          <Box>
            <Box display="flex" flexDirection="column">
              <Box mb={[1, 2]}>
                <Typography color="white" variant="h6">
                  Titile1
                </Typography>
              </Box>
              <Box>
                <Typography color="white" variant="body2">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make a type specimen
                  book. It has survived not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                  with desktop publishing software like Aldus PageMaker including versions of Lorem
                  Ipsum.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" flexDirection="column">
              <Box mb={[1, 2]}>
                <Typography color="white" variant="h6">
                  Titile2
                </Typography>
              </Box>
              <Box>
                <Typography color="white" variant="body2">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make a type specimen
                  book. It has survived not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                  with desktop publishing software like Aldus PageMaker including versions of Lorem
                  Ipsum.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
