import { FC, useCallback } from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { ArrowRight as ArrowRightIcon } from "../../icons/arrow-right";
import { slotBookServiceAppointmentApi } from "../../boundaries/slot-booking-backend/appointment/api";
import { useSelector } from "react-redux";
import { getToken } from "../auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import { setSelectedAppointment } from "./appointmentSlice";
import { makeStyles } from "@material-ui/styles";

interface JobDetailsStepProps {
  onNext?: () => void;
  onBack?: () => void;
}

interface slotProps {
  startTime: number;
  endTime: number;
}

interface availabilityDataType {
  slots: slotProps[];
  startTimeOffset: number;
}

const useStyles = makeStyles(() => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));


export const JobDetailsStep: FC<JobDetailsStepProps> = (props) => {
  const classes = useStyles();

  const { onBack, onNext, ...other } = props;

  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [availabilityData, setAvailabilityData] = useState<availabilityDataType>();
  const [prefferedSlot, setPrefferedSlot] = useState("");

  const token = useSelector(getToken);

  const handleStartDateChange = async (newValue: Date | null): Promise<void> => {
    setStartDate(newValue);
  };

  const handlePrefferedSlotChange = (event: SelectChangeEvent<string>) => {
    console.log(event.target);
    setPrefferedSlot(event.target.value);
  };

  const handleContinueOnClick = () => {
    if (!startDate || prefferedSlot === "") {
      return;
    }

    dispatch(
      setSelectedAppointment({
        dateAsNumber: Math.floor(startDate.getTime() / 1000),
        chosenSlot: prefferedSlot,
      })
    );

    if (onNext) onNext();
  };

  const getAvailabilityForDate = useCallback(async () => {
    if (!startDate) {
      return;
    }
    console.log(startDate);
    console.log(startDate.getTime());
    const response = await slotBookServiceAppointmentApi.getAvailabilityForDate({
      dateAsNumber: Math.floor(startDate.getTime() / 1000),
      token: token,
    });
    setAvailabilityData(response.data);
    setPrefferedSlot("");
  }, [startDate, token]);

  useEffect(() => {
    getAvailabilityForDate();
  }, [getAvailabilityForDate]);

  return (
    <div {...other}>
      <Typography variant="h6">Choose your Session!</Typography>
      <Box sx={{ mt: 3 }}>
        <Typography sx={{ mt: 3 }} variant="subtitle1">
          Please select your preferred date
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            mt: 3,
          }}
        >
          <MobileDatePicker
            label="Date"
            inputFormat="MM/dd/yyyy"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(inputProps) => <TextField {...inputProps} />}
          />
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography sx={{ mt: 3 }} variant="subtitle1">
            Please select your preferred time slot
          </Typography>
          <FormControl margin="normal" fullWidth variant="outlined">
            <InputLabel>Choose</InputLabel>
            <Select
              value={prefferedSlot}
              label="preffered-slot"
              name="preffered-slot"
              onChange={handlePrefferedSlotChange}
              MenuProps={{
                style: {
                  maxHeight: "200px",
                  minWidth: "150px",
                },
                PaperProps: {
                  className: classes.scrollbar,
                  style: {
                    width: "1px",
                  },
                },
              }}
            >
              {availabilityData &&
                availabilityData.slots &&
                availabilityData.slots.map((slot: slotProps) => {
                  return (
                    <MenuItem value={`${slot.startTime}-${slot.endTime}`}>{`${new Date(
                      slot.startTime * 60 * 1000
                    )
                      .toISOString()
                      .substr(11, 5)}-${new Date(slot.endTime * 60 * 1000)
                      .toISOString()
                      .substr(11, 5)}`}</MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button
          endIcon={<ArrowRightIcon fontSize="small" />}
          onClick={handleContinueOnClick}
          disabled={!startDate && prefferedSlot === ""}
          variant="contained"
        >
          Continue
        </Button>
        <Button onClick={onBack} sx={{ ml: 2 }}>
          Back
        </Button>
      </Box>
    </div>
  );
};

JobDetailsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
