import { Box, Typography } from "@mui/material";
import { ChosenColorCode } from "./standard-color";

export const ThirdSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        borderTop: "1px rgba(57,150,210,.4)",
        borderBottom: "1px rgba(57,150,210,.4)",
        marginTop: "20px",
        paddingTop: "35px",
        paddingBottom: "55px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "45px",
          position: "relative",
          maxWidth: "1140px",
          mx: "auto",
          "@media screen and (max-width: 991px)": {
            paddingTop: "20px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#201845",
            marginBottom: 0,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "130%",
            "@media screen and (max-width: 767px)": {
              fontSize: "28px",
            },
          }}
        >
          These companies have extended{" "}
          <span style={{ color: `${ChosenColorCode.CRACKTECH_CHOICE}` }}>offers</span> to our
          candidates!
        </Typography>
        <Box
          sx={{
            "@media screen and (max-width: 767px)": {
              display: "none",
            },
          }}
        >
          <img
            style={{
              marginTop: "40px",
            }}
            alt="hiring-companies-large"
            src="/static/images/new-landing-page/fourth-section/hiring-companies-large.png"
          />
        </Box>
        <Box
          sx={{
            "@media screen and (min-width: 767px)": {
              display: "none",
            },
          }}
        >
          <img
            style={{
              marginTop: "40px",
            }}
            alt="hiring-companies-large"
            src="/static/images/new-landing-page/fourth-section/hiring-companies-small.png"
          />
        </Box>
      </Box>
    </Box>
  );
};
