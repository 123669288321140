import { Box, Divider, Grid, Typography } from "@mui/material";

export const About = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        height="100vh"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage: "linear-gradient(to right, rgb(134 169 152), rgba(76, 175, 80, 0))",
        }}
      >
        <Box flex="1" color="#fff" textAlign={["center", "left"]}>
          <Box ml={[2, 3, 7]} mr={[2, 3]} mt={[3, 0]}>
            <Typography
              variant="h1"
              sx={{
                color: "#000000",
                fontSize: ["32px", "50px"],
                fontWeight: 700,
                lineHeight: 1.2,
              }}
            >
              About Us
            </Typography>
            <Typography>&nbsp;</Typography>
            <Typography
              variant="h2"
              sx={{ color: "#fefff8", fontSize: ["24px", "48px"], fontWeight: 700 }}
            >
              Land your dream job and get the compensation you deserve
            </Typography>
            <Typography>&nbsp;</Typography>
            <Typography variant="caption" sx={{ fontSize: "17px" }}>
              I’m Prakhar, and I'm on a mission to change the way you interview
            </Typography>
          </Box>
        </Box>
        <Box flex="1" height="100%">
          <img
            src="/static/images/about-page/founder.jpg"
            alt="Your Photo"
            style={{
              width: "100%",
              height: "100%",
              margin: "0",
              padding: "0",
              border: "none",
            }}
          />
        </Box>
      </Box>
      <Box my={[2, 7, 15]} mx={[2, 7, 15]}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" sx={{ color: "#161E2A" }}>
              I left my job at Google without a plan!
            </Typography>
            <Typography>&nbsp;</Typography>
            <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
              I am Prakhar and I have been working as a Senior Software Engineer in Google since
              2018. With unwavering determination and persistent effort, I delved into the world of
              programming, forging my path towards a fulfilling career in software engineering.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
              During my college years, I embarked on a journey to master data structures,
              algorithms, and system design interviews, seeking a reliable approach to tackle and
              conquer the challenging problems. However, what I encountered was a market flooded
              with low-quality information products that simply quoted college textbooks and freely
              available online resources. Determined to excel in these interviews, I set out to
              develop my own methodology that would enable me to consistently ace these challenges.{" "}
            </Typography>
            <Typography>&nbsp;</Typography>
            <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
              As of 2023, I have conducted over 5000+ hours of 1:1 interview and coaching sessions.
              Through my meticulously crafted methodology, I have witnessed a consistent
              transformation of individuals into top-tier candidates and high-performing engineers,
              who achieve comparable results and secure lucrative offers. Some companies that my
              clients have received offers from are- Google, Meta, Microsoft, Amazon, Salesforce and
              many other top Tech companies.{" "}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
