export enum AppRoutes {
  LOGIN = "/login",
  LOGOUT = "/logout",
  REGISTER = "/register",
  DEFAULT = "/",

  DASHBOARD = "/dashboard",
  DASHBOARD_BOOKING = "/dashboard/booking",
  DASHBOARD_PROFILE = "/dashboard/profile",
  DASHBOARD_NEW_BOOKING = "/dashboard/newbooking",

  TUTORING_SESSIONS = "/tutoring-sessions",
  INTERVIEW_SESSIONS = "/interview-sessions",

  NEW_LANDING_PAGE = "/new-landing-page",

  ABOUT = "/about",
  TESTIMONIALS = "/testimonial",
  CONTACT_US = "/contact",
}
