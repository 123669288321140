import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../app/type";

export interface AdminState {
  selectedUserId: string;
  selectedFoodEntryId: string;
}

const initialState: AdminState = {
  selectedUserId: "",
  selectedFoodEntryId: "",
};

export interface GetUsersPayload {
  token: string;
}

export interface SelectedUserIdPayload {
  userId: string;
}

export interface SelectedFoodEntryIdPayload {
  foodEntryId: string;
}

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSelectedUserId: (state, action: PayloadAction<SelectedUserIdPayload>) => {
      state.selectedUserId = action.payload.userId;
    },
    setSelectedFoodEntryId: (state, action: PayloadAction<SelectedFoodEntryIdPayload>) => {
      state.selectedFoodEntryId = action.payload.foodEntryId;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {},
});

export const { setSelectedUserId, setSelectedFoodEntryId, reset } = adminSlice.actions;

export const getSelectedUserId = (state: RootState) => state.admin.selectedUserId;

export const getSelectedFoodEntryId = (state: RootState) => state.admin.selectedFoodEntryId;

export default adminSlice.reducer;
