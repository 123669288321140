import type { FC } from "react";
import { ReactNode, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, Theme } from "@mui/material";
import { Box, Chip, Divider, Drawer, Typography, useMediaQuery } from "@mui/material";
import { Home as HomeIcon } from "../../icons/home";
import { Mail as MailIcon } from "../../icons/mail";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import { Users as UsersIcon } from "../../icons/users";
import { DashboardSidebarSection } from "./dashboard-sidebar-section";

interface DashboardSidebarProps {
  onClose?: () => void;
  open?: boolean;
}

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path: string;
}

interface Section {
  title: string;
  items: Item[];
}

const getSections = (): Section[] => [
  {
    title: "",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: "New Booking",
        path: "/dashboard/newbooking",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Bookings",
        path: "/dashboard/booking",
        icon: <UsersIcon fontSize="small" />,
        chip: (
          <Chip
            color="error"
            label={
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "600",
                }}
              >
                1
              </Typography>
            }
            size="small"
          />
        ),
      },
    ],
  },
  {
    title: "",
    items: [
      {
        title: "Support",
        path: "/dashboard/invoices",
        icon: <MailIcon fontSize="small" />,
        chip: (
          <Chip
            color="secondary"
            label={
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "600",
                }}
              >
                2
              </Typography>
            }
            size="small"
          />
        ),
      },
    ],
  },
  {
    title: "",
    items: [
      {
        title: "Account",
        path: "/dashboard/profile",
        icon: <UserCircleIcon fontSize="small" />,
      },
    ],
  },
];

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onClose, open } = props;

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const sections = useMemo(() => getSections(), []);

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link href="/"></Link>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section, index) => (
            <>
              <DashboardSidebarSection
                key={section.title}
                path={""} //TODO: change
                sx={{
                  mt: 2,
                  "& + &": {
                    mt: 2,
                  },
                }}
                {...section}
              />
              {sections?.length - 1 !== index && (
                <Divider
                  sx={{
                    borderColor: "#2D3748", // dark divider
                  }}
                />
              )}
            </>
          ))}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) => (theme.palette.mode === "dark" ? 1 : 0),
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
