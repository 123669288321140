import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/type";

type DashboardState = {
  isLoading: boolean;
};

const initialState: DashboardState = {
  isLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const selectIsLoading = (state: RootState) => state.dashboard.isLoading;

export default dashboardSlice.reducer;
