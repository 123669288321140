import { DashboardLayout } from "./dashboard-layout";
import { Route, Switch } from "react-router";
import { AppRoutes } from "../../routing/routes";
import { Calendar } from "../calendar/Calendar";
import { UserAccountSettings } from "./account/user-account-settings";
import { AppointmentSettings } from "../appointment/appointment-settings";
import { AllAppointments } from "../appointment/all-appointment";

export default function Dashboard() {
  return (
    <DashboardLayout>
      <Switch>
        <Route path={AppRoutes.DASHBOARD_BOOKING} render={() => <AllAppointments />} />
        <Route path={AppRoutes.DASHBOARD_PROFILE} render={() => <UserAccountSettings />} />
        <Route path={AppRoutes.DASHBOARD_NEW_BOOKING} render={() => <AppointmentSettings />} />

        <Route path={AppRoutes.DEFAULT} render={() => <>This is default</>} />
      </Switch>
    </DashboardLayout>
  );
}
