import axios from "axios";
import {
  CreateAppointmentRequest,
  CreateAppointmentResponse,
  GetAvailabilityForDateRequest,
  GetAvailabilityForDateResponse,
} from "./model";

const CLIENT_SERVICE_URL = "http://localhost:4001";

class SlotBookingServiceAppointmentApi {
  async getAvailabilityForDate(
    payload: GetAvailabilityForDateRequest
  ): Promise<GetAvailabilityForDateResponse> {
    const response = await axios.post(
      `${CLIENT_SERVICE_URL}/api/v1/appointment/availability`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }
    );

    return response.data;
  }

  async createAppointment(payload: CreateAppointmentRequest): Promise<CreateAppointmentResponse> {
    const response = await axios.post(`${CLIENT_SERVICE_URL}/api/v1/appointment`, payload.data, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });

    return response.data;
  }
}

export const slotBookServiceAppointmentApi = new SlotBookingServiceAppointmentApi();
