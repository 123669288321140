import { Container } from "@mui/material";
import CustomizedSnackbars from "./components/core/alert/Alert";
import IndeterminateLoader from "./components/core/loader/IndeterminateLoader";
import Header from "./components/header/Header";
import { SettingsConsumer, SettingsProvider } from "./contexts/settings-context";
import AppRouter from "./routing/AppRouter";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "./theme";
import { Calendar } from "./components/calendar/Calendar";
import { Profile } from "./components/calendar/planning/schedule-details/Profile";
import { DashboardLayout } from "./components/dashboard/dashboard-layout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings, saveSettings }) => (
            <ThemeProvider
              theme={createTheme({
                direction: settings.direction,
                responsiveFontSizes: settings.responsiveFontSizes,
                mode: settings.theme,
              })}
            >
              <Container disableGutters maxWidth={false} >
                <IndeterminateLoader />
                <CustomizedSnackbars />
                <AppRouter />
              </Container>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
