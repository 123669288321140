import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/type";

export interface AppointmentState {
  selectedAppointment: {
    dateAsNumberInSecs?: number;
    chosenSlotAsString?: string;
  };
}

const initialState: AppointmentState = {
  selectedAppointment: {},
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedAppointment: (
      state,
      action: PayloadAction<{
        dateAsNumber: number;
        chosenSlot: string;
      }>
    ) => {
      state.selectedAppointment.dateAsNumberInSecs = action.payload.dateAsNumber;
      state.selectedAppointment.chosenSlotAsString = action.payload.chosenSlot;
    },
  },
});

export const { reset, setSelectedAppointment } = appointmentSlice.actions;

export const getSelectedAppointment = (state: RootState) => state.appointment.selectedAppointment;

export default appointmentSlice.reducer;
