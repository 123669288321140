import type { FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CountryList } from "../../../utils/country-list";
import { useDispatch } from "react-redux";
import { registerAsync } from "../authSlice";
import { TimeZone } from "../../../utils/time-zone";
import { makeStyles } from "@material-ui/styles";
// import { useAuth } from '../../hooks/use-auth';
// import { useMounted } from '../../hooks/use-mounted';

const useStyles = makeStyles(() => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // "&::-webkit-scrollbar-thumb": {
    //   border: "none",
    //   boxShadow: "none",
    //   background: "#dadce0",
    //   borderRadius: "8px",
    //   minHeight: "40px",
    // },
  },
}));

export const JWTRegister: FC = (props) => {
  // const isMounted = useMounted();
  // const { register } = useAuth();
  const classes = useStyles();

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      country: "",
      timeZone: "Pacific Standard Time",
      phone: "",
      password: "",
      policy: false,
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      name: Yup.string().max(255).required("Name is required"),
      country: Yup.string().required("Please select a country!"),
      password: Yup.string().min(7).max(255).required("Password is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
      policy: Yup.boolean().oneOf([true], "This field must be checked"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        dispatch(
          registerAsync({
            ...values,
            givenName: values.name.split(" ")[0],
            familyName: values.name.split(" ")[1],
            role: "User",
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} autoComplete="off" {...props}>
      <TextField
        error={Boolean(formik.touched.name && formik.errors.name)}
        fullWidth
        helperText={formik.touched.name && formik.errors.name}
        label="Name"
        margin="normal"
        name="name"
        autoComplete="name"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.name}
      />

      <TextField
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        autoComplete="email"
        value={formik.values.email}
      />

      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />

      <FormControl
        margin="normal"
        error={Boolean(formik.touched.country && formik.errors.country)}
        fullWidth
        variant="outlined"
      >
        <InputLabel>Country</InputLabel>
        <Select
          value={formik.values.country}
          label="Country"
          name="country"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          MenuProps={{
            style: {
              maxHeight: "200px",
              minWidth: "150px",
            },
            PaperProps: {
              className: classes.scrollbar,
              style: {
                width: "1px",
              },
            },
          }}
          inputProps={{
            autoComplete: "on",
          }}
        >
          {CountryList.map((con) => {
            return <MenuItem value={con.country}>{`${con.country} (${con.code})`}</MenuItem>;
          })}
        </Select>
        {formik.touched.country && <FormHelperText>{formik.errors.country}</FormHelperText>}
      </FormControl>

      <TextField
        error={Boolean(formik.touched.phone && formik.errors.phone)}
        fullWidth
        helperText={formik.touched.phone && formik.errors.phone}
        label="Phone number"
        margin="normal"
        name="phone"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="tel"
        value={formik.values.phone}
        InputProps={{
          inputProps: {
            maxLength: 10,
            onKeyPress: (event) => {
              if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
              }
            },
          },
        }}
      />

      <FormControl
        margin="normal"
        error={Boolean(formik.touched.timeZone && formik.errors.timeZone)}
        fullWidth
        variant="outlined"
      >
        <InputLabel>Time zone</InputLabel>
        <Select
          value={formik.values.timeZone}
          label="Time zone"
          name="timeZone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          MenuProps={{
            style: {
              maxHeight: "300px",
              minWidth: "150px",
            },
            PaperProps: {
              className: classes.scrollbar,
              style: {
                width: "1px",
              },
            },
          }}
          inputProps={{
            autoComplete: "on",
          }}
          defaultValue="Pacific Standard Time"
        >
          {TimeZone.map((zone) => {
            return <MenuItem value={zone.value}>{zone.text}</MenuItem>;
          })}
        </Select>
        {formik.touched.timeZone && <FormHelperText>{formik.errors.timeZone}</FormHelperText>}
      </FormControl>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          ml: -1,
          mt: 2,
        }}
      >
        <Checkbox checked={formik.values.policy} name="policy" onChange={formik.handleChange} />
        <Typography color="textSecondary" variant="body2">
          I have read the{" "}
          <Link component="a" href="#">
            Terms and Conditions
          </Link>
        </Typography>
      </Box>
      {Boolean(formik.touched.policy && formik.errors.policy) && (
        <FormHelperText error>{formik.errors.policy}</FormHelperText>
      )}
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit as string}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Register
        </Button>
      </Box>
    </form>
  );
};
