import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import { isUserAuthenticated } from "../components/auth/authSlice";
import Login from "../components/auth/login/Login";
import { Register } from "../components/auth/signup/Register";
import { Calendar } from "../components/calendar/Calendar";

import AdminDashboard from "../components/dashboard/admin/AdminDashboard";
import Dashboard from "../components/dashboard/Dashboard";
import PublicDashboard from "../components/dashboard/public-dashboard";
import LandingPage from "../components/landing-page/LandingPage";
import { About } from "../components/public/about-page";
import { Testimonial } from "../components/public/testimonials-page";
import ProtectedRoute from "./PrivateRouter";
import { AppRoutes } from "./routes";

function AppRouter() {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const authenticationPath = "/login";

  return (
    <Switch>
      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        authenticationPath={authenticationPath}
        path={AppRoutes.DASHBOARD}
        render={() => <Dashboard />}
      />

      <Route path={AppRoutes.DEFAULT} render={() => <PublicDashboard />} />
    </Switch>
  );
}

export default withRouter(AppRouter);
