import { ContactForm } from "./contact-form";
import { Avatar, Box, Button, Container, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch } from "../../../app/hooks";
import { AppRoutes } from "../../../routing/routes";
import { push } from "connected-react-router";

export const ContactUs = () => {
  const dispatch = useAppDispatch();

  const handleHomeClick = () => {
    dispatch(push(AppRoutes.DEFAULT));
  };

  return (
    <Box
      component="main"
      sx={{
        display: "grid",
        gridTemplateColumns: {
          lg: "repeat(2, 1fr)",
          xs: "repeat(1, 1fr)",
        },
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            pl: {
              lg: 15,
            },
          }}
        >
          <Button
            component="a"
            onClick={handleHomeClick}
            startIcon={<ArrowBackIcon fontSize="small" />}
          >
            Home
          </Button>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Contact
          </Typography>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 6,
              mt: 8,
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                mr: 2,
              }}
              variant="rounded"
            >
              <MailIcon fontSize="small" />
            </Avatar>
            <Typography variant="overline">Contact sales</Typography>
          </Box>
          <Typography variant="h1">Talk to our account expert</Typography>
          <Typography sx={{ py: 3 }} variant="body1">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s
          </Typography>
          <Typography sx={{ color: "primary.main" }} variant="h6">
            Lorem Ipsum is simply dummy text of the
          </Typography>
          {/* <Box sx={{ pt: 2 }}>
            <img
              alt="logoipsum1"
              src="/static/contact/contact_logos.svg"
              style={{ maxWidth: "100%" }}
            />
          </Box> */}
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          px: 6,
          py: 15,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            pr: {
              lg: 15,
            },
          }}
        >
          <ContactForm />
        </Container>
      </Box>
    </Box>
  );
};
