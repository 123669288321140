import type { FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Alert, Box, Button, FormHelperText, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginAsync } from "../authSlice";
// import { useAuth, useMounted} from "../../../app/hooks;

export const JWTLogin: FC = (props) => {
  // const isMounted = useMounted();
  // const { login } = useAuth();

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        dispatch(loginAsync({ email: values.email, password: values.password }));
      } catch (err) {
        console.error(err);

        // if (isMounted()) {
        //   helpers.setStatus({ success: false });
        //   helpers.setErrors({ submit: err.message });
        //   helpers.setSubmitting(false);
        // }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} {...props} autoComplete="off">
      <TextField
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
        autoComplete="email"
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit as string}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Log In
        </Button>
      </Box>
    </form>
  );
};
