import { Box, Card, Container, Divider, Link, Typography } from "@mui/material";
import { JWTLogin } from "./JWTLogin";
import { useAppDispatch } from "../../../app/hooks";
import { push } from "connected-react-router";
import { AppRoutes } from "../../../routing/routes";

const Login = () => {
  const dispatch = useAppDispatch();

  const handleCreateNewAccountOnClick = () => {
    dispatch(push(AppRoutes.REGISTER));
  };

  return (
    <>
      <title>Login</title>
      <Box
        component="main"
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Card elevation={16} sx={{ p: 4 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">Log in</Typography>
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
                Sign in to CrackTech.io
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <JWTLogin />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box display="flex">
              <Link onClick={handleCreateNewAccountOnClick} color="textSecondary" variant="body2">
                Create new account
              </Link>
              <Link href="" color="blue" sx={{ marginLeft: "auto" }} variant="body2">
                Forgot password?
              </Link>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
