import { Box, Typography, Divider } from "@mui/material";

export const TutoringSessions = () => {
  return (
    <Box mx={[2, 5, 8]} my={[2, 5]}>
      <Box
        p={[2, 5]}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography textAlign="center" variant="h1">
          Get what you deserve!
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: "18px", color: "#595959", textAlign: "center", my: [1, 2] }}
        >
          The key to maximizing your compensation is to be gracious and kind and have a negotiation
          plan.
        </Typography>
      </Box>
      {/* DSA part */}
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent="center"
        mt={[2, 5]}
        mx={[1, 3, 8, 12]}
      >
        <Box display="flex" flexDirection="column" order={[2, 1]} mt={[4]}>
          <Typography variant="h5" sx={{ mb: [1, 2], textAlign: ["center", "left"] }}>
            Data structures and Algorithms
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            These are specialized educational sessions that focuses on teaching mentees the
            fundamental concepts and techniques related to data structures and algorithms that are
            of utmost importance for exceling in the interview. The class is usually delivered
            through a combination of lectures, discussions, and practical exercises.
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            It typically follows a structured curriculum that covers a wide range of topics.
            Students start by learning about basic and advance data structures such as arrays,
            linked lists, stacks, queues, trees, and graphs, and understanding their properties,
            operations, and use cases. It would be customized based on the potential organization
            that is being targeted by the candidate.
          </Typography>
        </Box>
        <Box mt={[1, 8]} ml={[1, 2, 5]} order={[1, 2]}>
          <img
            height={20}
            width={20}
            src="/static/images/tutorial-session/dsa-logo.png"
            alt="dsa-logo"
          />
        </Box>
      </Box>
      {/* divider horizontal line */}
      <Box display="flex" justifyContent="center">
        <Divider sx={{ my: [5, 10], width: "90%" }} />
      </Box>
      {/* system design part */}
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent="center"
        mx={[1, 3, 8, 12]}
      >
        <Box mr={[1, 2, 5]}>
          <img
            height={20}
            width={20}
            src="/static/images/tutorial-session/system-design-logo.png"
            alt="system-design-logo"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" sx={{ mb: [1, 2], textAlign: ["center", "left"] }}>
            System Design
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            These sessions focus on teaching the principles, methodologies, and techniques for
            designing complex and scalable systems which are highly valuable for acing software
            engineering interviews.
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            We will start by learning about system requirements analysis, system architecture,
            system modeling, and system integration. We will then move on to different design
            methodologies such as top-down, bottom-up, and iterative approaches, and best practices
            for system design, including modularity, scalability, maintainability, and reliability.
          </Typography>
        </Box>
      </Box>
      {/* Divider */}
      <Box display="flex" justifyContent="center">
        <Divider sx={{ my: [5, 10], width: "90%" }} />
      </Box>
      {/* Behavioral part */}
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent="center"
        mx={[1, 3, 8, 12]}
      >
        <Box display="flex" flexDirection="column" order={[2, 1]} mt={[4]}>
          <Typography variant="h5" sx={{ mb: [1, 2], textAlign: ["center", "left"] }}>
            Behavioral
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            These sessions provide personalized coaching and guidance to job seekers in one-on-one
            tutoring sessions, focusing on behavioral interview skills. I will assess your current
            level of proficiency and teach you effective behavioral interview techniques and
            strategies, including how to structure and articulate your responses as well as how to
            showcase their skills and experiences.
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="caption" sx={{ fontSize: "17px", color: "#595959" }}>
            We will dive into practice questions together I will provide detailed feedback on each
            of your answers. We will collaboratively refine any areas that need some fine-tuning,
            ensuring you are fully prepared for your interviews.
          </Typography>
        </Box>
        <Box mt={[1, 8]} ml={[1, 2, 5]} order={[1, 2]}>
          <img
            height={20}
            width={20}
            src="/static/images/tutorial-session/behavioral-logo.png"
            alt="behavioral-logo"
          />
        </Box>
      </Box>
    </Box>
  );
};
