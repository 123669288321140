import { DashboardLayout } from "./dashboard-layout";
import { Route, Switch } from "react-router";
import { AppRoutes } from "../../routing/routes";
import LandingPage from "../public/landing-page";
import { About } from "../public/about-page";
import { Testimonial } from "../public/testimonials-page";
import Login from "../auth/login/Login";
import { Register } from "../auth/signup/Register";
import { ContactUs } from "../public/contact-us/contact-page";
import { TutoringSessions } from "../sessions/tutoring-sessions/tutoring-sessions";
import { InterviewSessions } from "../sessions/interview-sessions/interview-sessions";
import { NewLandingPage } from "../new-landing-page/new-landing-page";

export default function PublicDashboard() {
  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={AppRoutes.LOGIN} render={() => <Login />} />

        <Route exact path={AppRoutes.LOGOUT} render={() => <LandingPage />} />

        <Route exact path={AppRoutes.REGISTER} render={() => <Register />} />

        <Route exact path={AppRoutes.ABOUT} render={() => <About />} />

        {/* <Route exact path={AppRoutes.TESTIMONIALS} render={() => <Testimonial />} /> */}

        <Route exact path={AppRoutes.TUTORING_SESSIONS} render={() => <TutoringSessions />} />

        <Route exact path={AppRoutes.INTERVIEW_SESSIONS} render={() => <InterviewSessions />} />

        <Route exact path={AppRoutes.CONTACT_US} render={() => <ContactUs />} />

        {/* <Route path={AppRoutes.DEFAULT} render={() => <LandingPage />} /> */}

        <Route exact path={AppRoutes.DEFAULT} render={() => <NewLandingPage />} />
      </Switch>
    </DashboardLayout>
  );
}
