import { Box, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box
      sx={{
        "@media screen and (max-width: 767px)": {
          zIndex: 888,
        },
        color: "#9dbfd4",
        backgroundColor: "#2a3950",
        paddingTop: "15px",
        paddingBottom: "15px",
        fontSize: "13px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          "@media screen and (max-width: 991px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
          width: "100%",
          maxWidth: "1140px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box
          sx={{
            "@media screen and (max-width: 767px)": {
              flexDirection: "column",
              alignItems: "center",
            },
            display: "flex",
          }}
        >
          <Box
            sx={{
              "@media screen and (max-width: 767px)": {
                textAlign: "center",
              },
              flex: 1,
            }}
          >
            <a
              href=""
              style={{
                color: "#9dbfd4",
                marginRight: "20px",
                fontSize: "12px",
                fontWeight: 500,
                textDecoration: "none",
                display: "inline-block",
                cursor: "pointer",
                fontFamily: "Inter",
              }}
            >
              Privacy Policy Terms and Conditions
            </a>
          </Box>
          <Typography
            sx={{ color: "#f0f3f6", textAlign: "right", fontSize: "13px", fontWeight: 400 }}
          >
            © Copyright 2023. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
